var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-transfer',{attrs:{"data-source":_vm.mockData,"show-search":"","titles":['可选人员', '已选择人员'],"list-style":{
    width: '46%',
  },"filterOption":function (inputValue, item) { return item.title.indexOf(inputValue) !== -1; },"target-keys":_vm.targetKeys,"show-select-all":false},on:{"change":_vm.handleChange,"search":_vm.handlesearch},scopedSlots:_vm._u([{key:"children",fn:function(ref){
    var ref_props = ref.props;
    var direction = ref_props.direction;
    var filteredItems = ref_props.filteredItems;
    var selectedKeys = ref_props.selectedKeys;
    var listDisabled = ref_props.disabled;
    var ref_on = ref.on;
    var itemSelectAll = ref_on.itemSelectAll;
    var itemSelect = ref_on.itemSelect;
return [_c('a-table',{style:({ pointerEvents: listDisabled ? 'none' : null }),attrs:{"row-selection":_vm.getRowSelection({
          disabled: listDisabled,
          selectedKeys: selectedKeys,
          itemSelectAll: itemSelectAll,
          itemSelect: itemSelect,
        }),"columns":direction === 'left' ? _vm.leftColumns : _vm.rightColumns,"data-source":filteredItems,"size":"small","custom-row":function (ref) {
          var key = ref.key;
          var itemDisabled = ref.disabled;

          return ({
          on: {
            click: function () {
              if (itemDisabled || listDisabled) { return; }
              itemSelect(key, !selectedKeys.includes(key));
            },
          },
        });
    }}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }