<template>
  <div class="bgStyle" style="text-align: center">
    <div class="bgStyles">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="apple" />
            角色成员
          </span>
          <div style="text-align: left">
            <p>角色详情</p>
            <a-divider />
            <a-form-model
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              layout="horizontal"
            >
              <a-form-model-item prop="name">
                <a-row :gutter="16">
                  <a-col :span="5">
                    <p style="text-align: right">角色名称:</p>
                  </a-col>
                  <a-col :span="16">
                    <a-input
                      v-model="ruleForm.name"
                      placeholder="管理员"
                      :disabled="!visible"
                    />
                  </a-col>
                </a-row>
              </a-form-model-item>
              <a-form-model-item prop="description">
                <a-row :gutter="16">
                  <a-col :span="5">
                    <p style="text-align: right">描 述:</p>
                  </a-col>
                  <a-col :span="16">
                    <a-input
                      v-model="ruleForm.description"
                      type="textarea"
                      :disabled="!visible"
                      placeholder="管理员，可创建项目、删除项目、管理项目成员"
                    />
                  </a-col>
                </a-row>
              </a-form-model-item>
              <a-form-model-item>
                <div>
                  <p>角色成员</p>
                  <a-divider />
                  <div>
                    <RoleTransfer
                      ref="roleTransfer"
                      :leftTableData="roleTransferCtr.leftTableData"
                      :rightTargetKeys="roleTransferCtr.rightTargetKeys"
                      @handleChange="roleTransferHandleChange(arguments)"
                      @handlesearch="roleTransferHandlesearch(arguments)"
                    />
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item>
                <div class="buttonDiv">
                  <a-button type="primary" @click="rolesClick(ruleForm)">
                    确定
                  </a-button>
                </div>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="apple" />
            角色权限
          </span>
          <div style="text-align: left">
            <div v-for="item in permissionGroups" :key="item.name">
              <a-collapse :bordered="false">
                <a-collapse-panel :header="item.displayName">
                  <div slot="extra">
                    <a-checkbox
                      style="float: left"
                      @click="CheckAllclick"
                      @change="onCheckAllListChange($event, item)"
                      :checked="isAllList(item)"
                    >
                    </a-checkbox>
                  </div>
                  <ul>
                    <div
                      v-for="permissions in item.permissions"
                      :key="permissions.name"
                      style="margin: 10px"
                    >
                      <a-checkbox
                        @change="onCheckAllChange($event, permissions)"
                        :checked="isAll(permissions)"
                        >{{ permissions.displayName }}
                      </a-checkbox>
                      <ul>
                        <div
                          style="display: inline; margin: 0px; padding: 10px"
                          v-for="children in permissions.children"
                          :key="children.name"
                        >
                          <a-checkbox
                            :value="children.name"
                            :checked="ispermissionTrue(children.name)"
                            @change="onChange($event, children)"
                            >{{ children.displayName }}
                          </a-checkbox>
                        </div>
                      </ul>
                    </div>
                  </ul>
                </a-collapse-panel>
              </a-collapse>
            </div>
            <div class="buttonDiv">
              <a-button type="primary" @click="permissionClick(ruleForm)">
                确定
              </a-button>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
// 显示名称及值
import RoleTransfer from "@/components/RoleTransfer";
import { getPermissionList } from "@/api/Permission.js";
import {
  updateRoles,
  createRoles,
  getUserPermissionList,
  getUserRole,
} from "@/api/roles.js";
import { getCurrentCompanyUsersList } from "@/api/companyuser.js";

export default {
  components: {
    RoleTransfer,
  },
  data() {
    return {
      permissionTrue: [], //当前用户存在权限
      permissionGroups: [], //所有权限组
      form: this.$form.createForm(this, { name: "coordinated" }),
      /** 表单格式验证规则 */
      rules: {
        name: { required: true, message: "名称不能为空", trigger: "blur" },
      },
      /** 当前角色实体 */
      ruleForm: {},
      roleTransferCtr: {
        leftTableData: [],
        rightTargetKeys: [],
      },
      visible: false,
    };
  },
  /** 组件生命周期创建前事件 */
  created() {
    this.visible = this.$route.params.visible;
    this.ruleForm = this.$route.params.param;
    if (!this.visible) {
      getUserPermissionList(this.ruleForm.id)
        .then((res) => {
          this.permissionTrue = res.permissionList;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("查询角色权限异常");
        });
      getUserRole(this.ruleForm.id)
        .then((res) => {
          this.roleTransferCtr.rightTargetKeys = res.memberList;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("查询角色用户异常");
        });
    }
    getPermissionList({})
      .then((res) => {
        this.permissionGroups = res;
      })
      .catch(() => {
        this.loading = false;
        this.$message.error("查询权限列表异常");
      });
  },
  /** 组件生命周期编译后事件 */
  mounted() {
    getCurrentCompanyUsersList().then((res) => {
      var datas = [];
      res.items.forEach((item) => {
        if (
          item.invitateState == 2 &&
          item.activeState === 1 &&
          item.companyUserRoleName !== "超级管理员"
        ) {
          const data = {
            key: item.userId.toString(),
            title: item.fullName,
            description: item.phoneNumber,
            chosen: false,
          };
          datas.push(data);
        }
      });
      this.roleTransferCtr.leftTableData = datas;
    });
  },
  methods: {
    /**
     * 穿梭器项变更事件
     * @param {Arrty} targetKeys[0] 穿梭组件右边选择项
     * @param {string} targetKeys[1] 穿梭方向
     * @param {Arrty} targetKeys[2] 当前穿梭值
     */
    roleTransferHandleChange(targetKeys) {
      this.roleTransferCtr.rightTargetKeys = targetKeys[0];
    },
    /**
     * 穿梭器查询事件
     * @param {string} targetKeys[1] 查询方向
     * @param {string} targetKeys[2] 过滤值
     */
    roleTransferHandlesearch() {},
    /**
     * 角色成员确定按钮事件
     * @param {obj} entity 角色成员信息
     *  */
    rolesClick(entity) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.ruleForm.memberList = this.roleTransferCtr.rightTargetKeys;
          var parms = {
            name: entity.name,
            description: entity.description,
            memberList: this.ruleForm.memberList,
          };
          if (this.visible) {
            createRoles(parms).then(() => {
              this.$message.success("新建成功");
            });
          } else {
            updateRoles(this.ruleForm.id, parms).then(() => {
              this.$message.success("修改成功");
            });
          }
        }
      });
    },
    /**
     * 角色权限确定按钮事件
     * @param {obj} entity 角色成员信息
     *  */
    permissionClick(entity) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var parms = {
            name: entity.name,
            description: entity.description,
            permissionList: this.permissionTrue,
          };
          if (this.visible) {
            createRoles(parms).then(() => {
              this.$message.success("新建成功");
            });
          } else {
            updateRoles(this.ruleForm.id, parms).then(() => {
              this.$message.success("权限修改成功");
            });
          }
        } else {
          this.$message.Warning("名称不能为空");
        }
      });
    },
    /**
     * 权限复选框(三级)checked值变更事件
     * @param {obj} event 复选框控件
     *  @param {obj} item 当前复选框值
     */
    onChange(event, item) {
      if (event.target.checked) {
        this.permissionTrue.push(item.name);
      } else {
        this.permissionTrue.splice(this.permissionTrue.indexOf(item.name), 1);
      }
    },
    /**
     * 权限多选框(二级)checked值变更事件
     * @param {obj} event 复选框控件
     *  @param {obj} item 当前复选框值
     */
    onCheckAllChange(event, item) {
      item.children.forEach((children) => {
        this.onChange(event, children);
      });
    },
    CheckAllclick(event) {
      event.stopPropagation();
    },
    /**
     * 权限多选框(一级)checked值变更事件
     * @param {obj} event 复选框控件
     *  @param {obj} item 当前复选框值
     */
    onCheckAllListChange(event, item) {
      item.permissions.forEach((permission) => {
        this.onCheckAllChange(event, permission);
      });
    },
    /**
     * 权限多选框(三级)checked检查
     * @param {obj} value 多选框值
     */
    ispermissionTrue(value) {
      return this.permissionTrue.indexOf(value) > -1;
    },
    /**
     * 权限多选框(一级)checked检查
     * @param {obj} item 权限实体数据
     */
    isAllList(item) {
      var isTrue = true;
      item.permissions.forEach((permission) => {
        if (!this.isAll(permission)) isTrue = false;
      });
      return item.permissions.length == 0 ? false : isTrue;
    },
    /**
     * 权限多选框(二级)checked检查
     * @param {obj} permission 权限实体数据
     */
    isAll(permission) {
      var isTrue = true;
      permission.children.forEach((children) => {
        if (!this.ispermissionTrue(children.name)) isTrue = false;
      });
      return permission.children.length == 0 ? false : isTrue;
    },
  },
};
</script>

<style lang="scss" scoped>
.bgStyles {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}
.buttonDiv {
  text-align: right;
  margin: 20px;
  margin-right: 50px;
}

::v-deep.ant-collapse-extra {
  float: left !important;
}
</style>
