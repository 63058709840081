<template>
  <a-transfer
    :data-source="mockData"
    show-search
    :titles="['可选人员', '已选择人员']"
    :list-style="{
      width: '46%',
    }"
    :filterOption="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
    :target-keys="targetKeys"
    @change="handleChange"
    :show-select-all="false"
    @search="handlesearch"
  >
    <template
      slot="children"
      slot-scope="{
        props: {
          direction,
          filteredItems,
          selectedKeys,
          disabled: listDisabled,
        },
        on: { itemSelectAll, itemSelect },
      }"
    >
      <a-table
        :row-selection="
          getRowSelection({
            disabled: listDisabled,
            selectedKeys,
            itemSelectAll,
            itemSelect,
          })
        "
        :columns="direction === 'left' ? leftColumns : rightColumns"
        :data-source="filteredItems"
        size="small"
        :style="{ pointerEvents: listDisabled ? 'none' : null }"
        :custom-row="
          ({ key, disabled: itemDisabled }) => ({
            on: {
              click: () => {
                if (itemDisabled || listDisabled) return;
                itemSelect(key, !selectedKeys.includes(key));
              },
            },
          })
        "
      />
    </template>
  </a-transfer>
</template>

<script>
const leftTableColumns = [
  {
    dataIndex: "title",
    title: "用户名",
  },
  // {
  //   dataIndex: "description",
  //   title: "描述",
  // },
];
const rightTableColumns = [
  {
    dataIndex: "title",
    title: "用户名",
  },
];

import difference from "lodash/difference";
export default {
  props: {
    leftTableData: { type: Array, default: () => [] },
    rightTargetKeys: { type: Array, default: () => [] },
  },
  data() {
    return {
      /** 左边数据源 */
      mockData: [],
      /** 右边选择项 */
      targetKeys: [],
      leftColumns: leftTableColumns,
      rightColumns: rightTableColumns,
    };
  },
  methods: {
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    /**
     * 穿梭器项变更事件
     * @param {Arrty} targetKeys 穿梭组件右边选择项
     * @param {string} direction 穿梭方向
     * @param {Arrty} moveKeys 当前穿梭值
     */
    handleChange(targetKeys, direction, moveKeys) {
      this.$emit("handleChange", targetKeys, direction, moveKeys);
    },
    /**
     * 穿梭器查询事件
     * @param {string} direction 查询方向
     * @param {string} value 过滤值
     */
    handlesearch(direction, value) {
      this.$emit("handlesearch", direction, value);
    },
  },
  watch: {
    leftTableData: {
      handler() {
        this.mockData = this.leftTableData;
      },
      deep: true,
      immediate: true,
    },
    rightTargetKeys: {
      handler() {
        this.targetKeys = this.rightTargetKeys;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
