import {
  updateRequest,
  getRequest,
  postRequest,
  deleteRequest,
} from "@/utils/http";

/** 分页查询角色列表集合 */
export const getRolesList = (parm) => getRequest(`/identity/role/list`, parm);
/** 查询单条角色 */
export const getRoles = (id) => getRequest(`/identity/role?id=${id}`);
/** 修改角色 */
export const updateRoles = (id, parm) =>
  updateRequest(`/identity/role/${id}`, parm);
/** 创建角色 */
export const createRoles = (parm) => postRequest(`/identity/role`, parm);
/** 删除角色 */
export const deleteProjectTemplate = (id) =>
  deleteRequest(`/identity/role/${id}`);

/** 查询用户权限 */
export const getUserPermissionList = (id) =>
  getRequest(`/identity/role/getUserPermissionList/${id}`);

/** 查询用户角色 */
export const getUserRole = (id) =>
  getRequest(`/identity/role/getUserRole/${id}`);
